import React from 'react';
import Navigation from './navComponent'
import './styles/projects_styles.css';


class Projects extends React.Component {
  render() {
    return <div className="projects-body-flex" id="ProjectsBodyFlex">
             <Navigation id="1"/> 
        
        <div className="container" id="ProjectsContainer">
            <div className="projects title-container" id="ProjectsTitleContainer">
             <div className="title-text" id="ProjectsTitleText">
                   <h1>projects</h1>
                </div>
            </div>
        </div>
        <div className="container" id="ExperienceContainer">
            <div className="experience title-container" id="ExperienceTitleContainer">
                <div className="title-text" id="ExperienceTitleText">
                    <h1>experience</h1>
                </div>
            </div>
        </div>
    </div>
  }
}
export default Projects