import React from "react"
import "../../styles/landingPage.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../scripts/navScript";
import { Link } from 'react-router-dom';

AOS.init();


function Body(){


    return <div className="body1" id="Body_1">
        <div className="content1">
            <div className="bodyText" id="aboutButton" data-aos="fade-right" data-aos-duration="2000" >
                <Link to="/about">
                about
                </Link>
            
            </div>
            <div className="bodyText" id="projectsButton" data-aos="fade-in" data-aos-duration="2000">
            <Link to="/projects">
                projects
            </Link>
            </div>
            <div className="bodyText" id="contactbutton" data-aos="fade-left" data-aos-duration="2000">
                contact
            </div>         
        </div>


    </div>
    
}




export default Body;        