



export function AboutContent(){
    document.getElementsByClassName("content1")[0].style.display = "none";
    document.getElementsByClassName("aboutContainer")[0].style.visibility = "visible";
    document.getElementsByClassName("exit")[0].style.display = "";

}


export default AboutContent;