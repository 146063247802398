import React from "react"
import "../../styles/landingPage.css"



function Header(){

    return(
    <div>
       <div className="welcomeTxt" id="welcomeTxt">
            <div className="text">welcome</div>
       </div>    
    </div>
    )

}

export default Header;