import React from 'react'
import './styles/navigation.css'
import { Link } from 'react-router-dom'

class Navigation extends React.Component{
    render(){

        if(this.props.id == 1){
            return <div className="nav-body">
            <div className="nav-title"><h3>menu</h3></div>
                <div className="options">
                    <Link to='/about'>
                        <div className="nav-items"><h3>about</h3></div>
                    </Link>
                    <Link to='/contact'>
                    <div className="nav-items"><h3>contact</h3></div>
                    </Link>
                    {/* <div className="nav-items"></div> */}
            </div>
        </div>
        
        } 
        if(this.props.id == 2)
        {
            return <div className="nav-body">
                <div className="nav-title"><h3>menu</h3></div>
                  <div className="options">
                <Link to='/contact'>
                    <div className="nav-items"><h3>contact</h3></div>
                </Link>
                <Link to='/projects'>
                    <div className="nav-items"><h3>projects</h3></div>
                </Link>
                    {/* <div className="nav-items"></div> */}
                </div>
            </div>
        }

        if(this.props.id == 3)
        {
            return <div className="nav-body">
            <div className="nav-title"><h3>menu</h3></div>
                <div className="options">
                <Link to='/about'>
                    <div className="nav-items"><h3>about</h3></div>
                </Link>
                <Link to='/projects'>
                    <div className="nav-items"><h3>projects</h3></div>
                </Link>
                    {/* <div className="nav-items"> option 3</div> */}
                </div>
            </div>
        }

        return null;


    }
}


export default Navigation;
