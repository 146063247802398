import React from 'react'
import '../src/styles/contact.css'


function Contact(){
    return <div>

        <h1> HELLO </h1>
        <div className="LI-container">
        <div className="LI-profile-badge"  data-version="v1" data-size="medium" data-locale="en_US" data-type="vertical" data-theme="dark"
         data-vanity="cormac-dunne-361403188"><a class="LI-simple-link" href='https://uk.linkedin.com/in/cormac-dunne-361403188?trk=profile-badge'>Cormac Dunne</a></div>
        </div>
    </div>
}

export default Contact;