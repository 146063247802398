import React from 'react'
import "../src/styles/aboutStyles.css"
import cormacImg from "../src/media/cormac.jpg"
import back from "../src/media/stock-images/back.png"
import gym from "../src/media/weight.png"
import books from "../src/media/books.png"
import PS from "../src/media/playstation.png"
import { Link } from 'react-router-dom';
import Navigation from './navComponent';

class About extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

  render() {
        return <div ref="myRef">
            <div className="about-title" id="About-Title" data-aos="fade-down" data-aos-duration="3000">
                  <h1>A Little Bit About Me</h1>
            </div>

        <Navigation id="2"/>

            <div className="aboutContainer">
             
            {/* <div className="page-title">about</div> */}
    



                <div className="about-item-1"  id="About_Item_1" data-aos="fade-in" data-aos-duration="2000">
                    <div className="image-container" id="Image_Container">
                        <img className="about-image-1" id="About_Image_1" src={cormacImg} />
                    </div>

                    <div className="text-container" id="Text_Container">
                        <div className="text">
                            <h2>There's Something About Cormac</h2>
                            <p> <em>Hi</em>, I'm Cormac. Queer name, I'm often told, but I guess we can thank my Irish heritage for that.
                                I'm currently an Apprentice Software Developer, studying a level 3 qualification with QA.
                                <br/>
                                <br/>
                                It took me a short while to really realise what is was I wanted to do, you know, asking a 16 year old
                                cormac just finishing his exams in secondary school what exactly he wants to do for the rest of his life was
                                enough to induce an <em>existential crisis</em>. So after studying a few A-Levels such as politics, sociology and 
                                psychology, which were all very thought provoking, I ended up working in a very distant field, electronic manufacturing, working my
                                way up to a <em>Junior Technician</em> role, where I tested and diagnosed faulty electronic assemblies and in my final
                                days, even built entire test solutions to customer specifications.
                                <br/>
                                <br/>
                                But alas, this was wholly unfulfilling and I had ascertained exactly what it was I wanted to do.
                                I wanted to <em>code</em>.
                                <br/>
                                <br/>
                                So, in an albeit dramatic fashion, I dropped my tools and pursued my dream career.
                                <br></br>
                                I picked up a high paying, quite labour intensive role, in a medical sterilisation facility
                                whilst in my spare time taught my self the web fundamentals. within 6 months I had accidentally worked my way up to<br/>
                                 <em>Team Leader</em>. I guess I can't help but do well! 
                                <br/>
                                <br/>
                                Finally, a position local to me was advertised for an apprentice developer. 
                                so i went for it and managed to land the position. <em> Maybe dreams do come true?</em>
    
                            </p>
                        </div>
                    </div>      
                </div>
                <div className="hobbie-header-container" id="Hobbie-Header-Container" data-aos="fade-in" data-aos-duration="2000">
                    <h1 className="hobbie-header" id="Hobbie_Header">
                        hobbies
                    </h1>
                </div>
                <div className="about-item-2" id="About-Item-2" data-aos="fade-in" data-aos-duration="2000">
              
                    <div className="hobbie-container" id="Gym-Cont" data-aos="fade-right" data-aos-duration="2000">
                        <div className="hobbie-title" id="Hobbie_Title"> 
                            <h1>GYM</h1>
                        </div>
                        <img className="hobbie" id="Gym" src={gym}/>
    
                        <div className="hobbie-body" id="Hobbie_Body">
                             <p> Yup, i'm of <em>those</em>. A frequent gym hitter. <br/>You may find me sweating it out on the bikes, <br/>trying to lift something a little too heavy
                                 or struggling to crack a chin up.
                             </p>
                        </div>
                    </div>
    
                    <div className="hobbie-container" id="Books-Cont" data-aos="fade-left" data-aos-duration="2000">
                    <div className="hobbie-title" id="Hobbie_Title"> 
                            <h1>READING</h1>
                        </div>
    
                        <img className="hobbie" id="Reading" src={books}/>
                        <div className="hobbie-body" id="Hobbie_Body">
                             <p>
                                 i'm <em>trying</em> to read more, so much so, i'm now calling it a hobby.
                             </p>
                        </div>
    
                    </div>
                    <div className="hobbie-container" id="PS-Cont" data-aos="fade-right" data-aos-duration="2000">
                    <div className="hobbie-title" id="Hobbie_Title"> 
                            <h1>GAMING</h1>
                    </div>
                        <img className="hobbie" id="PS" src={PS}/>
                        <div className="hobbie-body" id="Hobbie_Body"> 
                            <p>I enjoy a good bit of gaming, I would definitely lump my self into the category of <em>'casual gamer'</em>,
                            I'm a sucker for a team deathmatch on a solid First Person Shooter. <br/>
                            <br/>
                            </p>
                        </div>
                    </div>
                    
                </div>
    {/* 
                <div className="about-item-3">
                    
                </div> */}
    
    
            </div>
    </div>
        
    }
}
export default About