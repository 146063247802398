import React from 'react';
import './App.css';
import Header from "./LandingPage/components/welcome.js"
//import '../src/LandingPage/styles/LandingPage.css'
import Body from "../src/LandingPage/components/body";
import Arrow from './LandingPage/components/arrow';


function App() {
  return (
    <div className="App">
      <div className="bg">
        <Header/>
        <Arrow />
        <Body />
      </div>
    </div>
    
  );
}

export default App;
