import React from "react";
// import "../scripts/arrowScript"

function Arrow(){
    return <div>
        <div className="arrow" id="arrow">
                <span></span>
                <span></span>
                
        </div>
    </div>


}

export default Arrow;
